import React from 'react'

const strokeStyle = {vectorEffect: 'non-scaling-stroke'}

const Teenagers = () => (
  <svg
    viewBox='0 0 200 200'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    preserveAspectRatio='xMidYMid'
    width='1em'
    height='1em'
  >
    <path d='M104.9 131.3h-9v33.4h-7c-.7-25.8-1.5-51.6-2.2-76.4h-8.4c1.5-4 2.6-7.1 3.8-10.2 1.8-4.5 10.6-5.7 13.6-1.9 3.3 4 5.6 4 8.7.1 3.1-3.9 12.1-2.7 14 1.8 1.2 2.9 2.3 5.9 3.9 10l-8.8.4c-.7 24.4-1.4 50.2-2.1 76.3h-6.7v-33.5zM36.2 77c5.2-4 12.5-2.3 14.8 3.4 1 2.4 2.3 5.8 3.3 8.6-3 .9-6.8 1.1-9.1-1.4v26.9H19.5V89.1h-8.9c1.7-4.5 2.9-8.1 4.5-11.4 1.6-3.4 10.4-3.7 12.9-.6 3.2 4 5.3 3.9 8.3-.3l-.1.2zM169.5 78.8c2.1-1.3 4.1-3.6 6.3-3.8 8.8-.8 8.8-.6 13.4 13.3l-8.8.5v25.1h-25.8V89l-8.2-.5c3-16.3 10.6-16.7 19.3-10.1l3.8.4zM120.5 58.8l-5.8-6.3c.8 8.2-4.1 15.8-11.1 17.3-7.7 1.7-15.7-2.3-17.6-10.9-1.2-5.5-1.4-12.2 2.8-17.5 3.8-4.8 8.8-6.4 14.6-5.2 2.5.5 3.8-.1 5.2-2.2 2.2-3.4 5.7-4.6 9.6-2.8 4 1.8 5.3 5.1 4.6 9.4-.9 5.9-1.5 11.9-2.3 18.2zM29.6 164.9h-9.9V117h25.2v47.8h-9.4V126h-5.9v38.9zM161.2 40.9c-1.7-6.2-2.4-11.3 2.8-13.9 2.7-1.1 5.7-1.1 8.4.1 4.9 2.6 4.4 7.5 2.9 12.2 6.3.7 8.8 4.2 7.8 10.6-.4 2.6-.8 5.1-1 7.7-.5 5.6-4.4 10.7-9.6 12.1-5.7 1.6-11.4.7-14.9-4.3-4.6-6.7-5.4-14.5-3.7-22.3.6-2.9 2.6-5.2 7.3-2.2zM178.4 165H172v-24.5c0-7.9 0-7.9-8.9-6.7V165h-6.5l-2.1-47.8h25.9c-.6 16-1.3 31.7-2 47.8zM41.7 37.7c1 2.1 1.5 4 2.7 5.4 8.6 9.8.1 27.1-9.2 28.4C27 72.7 19.8 67.8 17.3 58c-3-11.4 5.4-21.2 17.1-20.3 2.6.1 5.2 0 7.3 0z' stroke='transparent' style={strokeStyle} fill='currentColor' />
    <path d='M78.7 89.6c1.8.7 3.6-.3 5.3.3-3.6 10-7.6 21.2-11.3 30.7-.4 1-2.4 1.4-3.7 2.1-.3-1.5-1.2-3.1-.7-4.4 3.3-9.3 6.7-18.6 10.4-28.7zM116.3 90c1.8-.6 3.7.2 5.4-.3 3.6 10 7.2 19.7 10.6 29.4.4 1-.6 2.5-1 3.8-1.3-.9-3.4-1.5-3.8-2.8-3.7-9.3-7.6-20-11.2-30.1zM146.4 89.7c1.7.6 3.5-.2 5.2.4-3.5 9.7-7.3 20.5-10.9 29.9-.4 1.1-2.3 1.7-3.5 2.6-.4-1.4-1.5-3-1.1-4.1 3.2-9.5 6.7-19 10.3-28.8zM48.2 90.8c1.8-.7 4-.3 5.2-.7.4 1.2 7.1 19 10.1 27.8.5 1.5-.5 3.4-.7 5.2-1.3-1.2-3.3-2.1-3.8-3.5-3.6-9-7.3-19.2-10.8-28.8zM11.3 90.1c1.8.7 3.7-.4 5.3.2-3.6 9.7-7.5 20.9-11.1 30.3-.4 1.1-2.4 1.6-3.6 2.4-.3-1.4-1.3-3-.9-4.2 3.2-9.4 6.7-18.7 10.3-28.7zM183.5 90.3c1.7-.6 3.4 0 5.2-.6 3.6 9.8 7.1 19.2 10.3 28.8.4 1.1-.7 2.8-1.1 4.2-1.2-.9-3-1.5-3.5-2.6-3.5-9.3-7.2-19.8-10.9-29.8zM104.5 173.4v-6.3l8.3-.4-.7 2.1 5.7 3.1-.5 1.5h-12.8zM88.5 167.1c6.8-.6 10.2 1.1 12.5 6.4H88.5v-6.4zM183.9 173.8h-9.3c-.8 0-1.6-.1-2.7-.3v-7c2.8.6 5.4.7 7.7 1.7 1.9.9 3.4 2.8 5 4.2l-.7 1.4zM167.7 173.6h-12v-7.1c2.7.6 5.3.8 7.6 1.8 1.9.9 3.4 2.7 5.1 4l-.7 1.3zM36.4 173.6v-6c6.3-1.2 10.6.8 12.4 6H36.4zM32.6 173.6H20.4v-6.3h8.1l-1.1 1.7 5.9 3.2-.7 1.4zM169.5 78.8l-3.8-.4c-.7-1.7-1.3-3.5-2.2-5.8h7.9l-1.9 6.2zM36.4 76.8c-5.5 3-6.8 2.4-7.2-3.5 1.1-.1 2.1-.3 3.2-.3 1-.1 1.9 0 3.1 0 .2 1.5.4 2.7.6 4 .1 0 .3-.2.3-.2zM104.6 73.8c-1.5 1.3-3 3.6-4.5 3.5-1.3 0-2.6-2.5-3.9-4l.9-1.2h6.6l.9 1.7z' stroke='transparent' style={strokeStyle} fill='currentColor' />

  </svg>
)

export default Teenagers
